import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Browser from '@/support/browser';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import PlayerUtil from '@/support/playerUtil';
import StudioPageBase from '@/support/studioPageBase';
import Util from '@/support/utility';
import { StudioModule } from '@/store/studio';
import { IVideo } from '@/interfaces';

interface ICategoryStudiosDto {
    name: string;
    studios: SUR.AzureStudioDto[];
}

@Component
export default class TeamStudioListComponent extends StudioPageBase {
    options = {
        skipAutoPlay: Browser.getBoolParam('skipAutoPlay', false),
        maxLiveVideos: Browser.getIntParam('maxLiveVideos', 3),
        autoRefresh: !Browser.getBoolParam('noRefresh', false) && Browser.getBoolParam('autoRefresh', true),
        debugSettings: Browser.getBoolParam('debugSettings', false),
        fakeLiveVideos: Browser.getIntParam('fakeLiveVideos', 0),
        refreshInterval: Browser.getIntParam('refreshInterval', 5 * 60) * 1000,
        refreshTimeout: Browser.getIntParam("refreshTimeout", 300),
    };

    loading = true;

    liveVideos: IVideo[] = [];

    refresh = {
        refreshIntervalId: 0,
        lastRefreshCheck: new Date(),
        refreshIntervalMs: 5 * 60 * 1000,
    };

    get showLiveVideos() {
        return this.liveVideos.filter(item => !item.dontPlay).length > 0;
    }

    get categoryStudios() {
        let categoryStudios: ICategoryStudiosDto[] = [];

        let addedStudios: number[] = [];

        if (!this.serverData.isAdmin) {
            let studios: SUR.AzureStudioDto[] = [];
            StudioModule.studios.forEach(studio => {
                if (studio.isOwner) {
                    studios.push(studio);
                    addedStudios.push(studio.studioId);
                }
            });

            if (studios.length > 0) {
                let categoryStudio = {
                    name: 'My Portals',
                    studios: studios
                };
                categoryStudios.push(categoryStudio);
            }
        }
        else if (this.serverData.isDeveloper) {
            let studios: SUR.AzureStudioDto[] = [];

            let studio = this.studios.find(item => item.studio == 'AzureTest');
            if (studio) {
                studios.push(studio);
                addedStudios.push(studio.studioId);

                let categoryStudio = {
                    name: 'My Portals',
                    studios: studios
                };
                categoryStudios.push(categoryStudio);
            }
        }

        this.categories.forEach(category => {

            let studios: SUR.AzureStudioDto[] = [];
            StudioModule.studios.forEach(studio => {
                if (null == addedStudios.find(item => item == studio.studioId)) {
                    if (studio.categoryId == category.id) {
                        studios.push(studio);
                        addedStudios.push(studio.studioId);
                    }
                }
            });

            if (studios.length > 0) {
                let categoryStudio = {
                    name: category.name,
                    studios: studios
                };
                categoryStudios.push(categoryStudio);
            }

        });

        return categoryStudios;
    }

    created() {
        super.created('teamStudioList');

        Debug.setDebugModule('App', this);
    }

    mounted() {
        super.mounted();

        this.getLiveAssets();

        this.refresh.refreshIntervalId = setInterval(() => {
            //Debug.log("refresh from refreshInterval", this.options.refreshTimeout);
            this.refreshPage();
        }, this.options.refreshInterval);

    }

    beforeDestroy() {
        this.liveVideos.splice(0, this.liveVideos.length);

        if (this.refresh.refreshIntervalId) {
            clearInterval(this.refresh.refreshIntervalId);
            this.refresh.refreshIntervalId = 0;
        }
    }

    showError(error: string, error2: string = null) {
        Debug.error("showError", error, error2);
        Util.showToast(error, true);
        //window.location.href = '/Error';
    }

    showNotAuthorized() {
        Debug.error("Not authorizied");
        window.location.href = '/NotAuthorized';
    }

    protected onLoaded(loaded: boolean) {
        super.onLoaded(loaded);

        if (!loaded || this.studios.length == 0) {
            PlayerUtil.redirectToNotAuthorized(this.$router);
            return;
        }

        if (this.studios.length == 1) {
            PlayerUtil.redirectToStudio(this.$router, this.studios[0].studio);
            return;
        }

        setTimeout(() => {
            this.loading = false;
        }, 500);
    }

    async getLiveAssets() {
        try {
            if (this.options.skipAutoPlay)
                return false;

            let model: SUR.PlaylistDto = {
                studio: null,
                hash: null,
                cached: false,
                fakeNewVideos: 0,
                fakeLiveVideos: this.options.fakeLiveVideos
            };
            let response = await PlayerApi.liveAssets(model);

            let videos = <IVideo[]><any>response;

            // limit live playing videos for performance reasons
            if (videos.length > this.options.maxLiveVideos)
                videos = videos.slice(0, this.options.maxLiveVideos);

            PlayerUtil.addVideoListPrivateMembers(videos);

            PlayerUtil.synchronizeVideos(this.liveVideos, videos);

            //Debug.log('getLiveAssets', this.liveVideos.length, 'liveVideos');

            return true;
        } catch (err) {
            let message = err.message || 'ERROR';

            Debug.error('getLiveAssets', err, message);

            this.liveVideos.splice(0, this.liveVideos.length);

            return null;
        }
    }

    getLivePlayers() {
        return this.$children.filter(child => {
            let tag = child.$vnode.tag;
            return tag.startsWith('vue-component-') && tag.endsWith('-PlayerComponent');
        });
    }

    async refreshPage(force: boolean = false) {
        const now = +new Date();
        force = force || false;
        if (!force) {

            const timeDiff = Math.abs(now - +this.refresh.lastRefreshCheck);
            if (timeDiff < this.options.refreshTimeout) {
                //Debug.log("refreshPage refresh abort to early", this.options.refreshTimeout / (60 * 1000), now, this.refresh.lastRefreshCheck);
                return false;
            }
        }

        this.refresh.lastRefreshCheck = <Date><any>now; // won't be saved unless use cookies

        await this.getLiveAssets();

        return true;
    }

    clearAllTimers() {
        try {
            if (this.refresh.refreshIntervalId) {
                try {
                    clearInterval(this.refresh.refreshIntervalId);
                } catch (err) {
                    //ignored
                }
                this.refresh.refreshIntervalId = null;
            }
        } catch (err) {
            // ignored
        }
    }

    onPlaying(video: IVideo) {
    }

    onError(video: IVideo) {
        video.dontPlay = true;
    }

    onEnded(video: IVideo) {
        video.dontPlay = true;
    }

    dumpChildren() {
        this.$children.forEach((child, idx) => {
            Debug.log(idx, child.$vnode.tag, child);
        });
    }

    testBeforeDestroy() {
        Debug.log('teamStudioList beforeDestroy');
        this.liveVideos.splice(0, this.liveVideos.length);
    }
}
